/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import './SideHeader.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  EvoLogo, mapPin, car, help, roaming, signOut,
  org,
  burger,
  dfMenuLogo,
  close,
  accountIcon,
  link,
  success,
  arrowNext,
  EvoLogoShort,
  charging,
  graphicsBar
} from '../../Icons/Icons';
import { Context } from '../..';
import { OrgRow } from '../../models/response/OrgResponse';
import { UserResponse } from '../../models/response/AuthResponse';
import LargeButtonWithIcon from '../buttons/LargeButtonWithIcon';

// Header menu

interface HeaderProps {
  setSelectedMenuItem: (menuItem: string) => void;
}

interface IOrgData {
  rows: OrgRow[];
  total: number;
}

const SideHeader: React.FC<HeaderProps> = ({ setSelectedMenuItem }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { store } = useContext(Context);
  const [selectedItem, setSelectedItem] = useState('');
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [menuLogo, setMenuLogo] = useState<React.ReactNode | null>(null);

  const handleBurgerOpen = () => {
    if (isBurgerOpen) {
      setIsBurgerOpen(false);
    } else setIsBurgerOpen((prev) => !prev);
  };

  const handleClickOutsideBurger = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.closest('.sideHeader-burger-menuItems-container') === null
      && target.closest('.menu-container-burger-icon') === null) {
      setIsBurgerOpen(false);
    }
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsBurgerOpen(false);
  };

  const handleLogOut = async () => {
    try {
      await store.logout();
      localStorage.clear();
      navigate('/login');
    } catch (error) {
      // console.error('Ошибка входа:', error);
    }
  };

  useEffect(() => {
    if (store.mapCar === 'carFromMap') {
      setSelectedMenuItem('Автопарк');
      setSelectedItem('Автопарк');
      store.setMapCar('');
    }
    if (store.mapStation === 'stationFromMap') {
      setSelectedMenuItem('Сеть ЭЗС');
      setSelectedItem('Сеть ЭЗС');
      store.setMapStation('');
    }
    switch (true) {
      case location.pathname.startsWith('/fleet/car'):
        setSelectedItem('Автопарк');
        break;
      case location.pathname === '/map':
        setSelectedItem('Карта');
        break;
      case location.pathname.startsWith('/groups') || location.pathname.startsWith('/rate') || location.pathname.startsWith('/charging') || location.pathname.startsWith('/chargingSessions'):
        setSelectedItem('Сеть ЭЗС');
        break;
      case location.pathname.startsWith('/organizations'):
        setSelectedItem('Организации');
        break;
      case location.pathname.startsWith('/analytics'):
        setSelectedItem('Аналитика');
        break;
      default:
        setSelectedItem('');
    }
  }, [location.pathname, selectedItem, store.mapCar, store.mapStation]);

  useEffect(() => {
    const domain = window.location.hostname;

    if (domain.includes('dfm-assist')) {
      setMenuLogo(dfMenuLogo);
    } else {
      setMenuLogo(EvoLogo);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideBurger);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideBurger);
    };
  }, []);

  // AccDropDown

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserResponse | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<string | null>('');
  const [orgData, setOrgData] = useState<IOrgData | null>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.closest('.sideHeader-dropdown-options') === null &&
      target.closest('.sideHeader-dropdown-button') === null &&
      target.closest('.adaptive-sideHeader-dropdown-button') === null) {
      setIsOpen(false);
    }
  };

  const handleOrgClick = async (orgId: string, option: OrgRow) => {
    try {
      setSelectedAccount(option.name);
      setIsOpen(false);

      localStorage.setItem('selectedAccount', option.name);
      localStorage.setItem('selectedInn', option.inn);
      await store.loginOrg(orgId);
      await store.getUser();
      navigate('/fleet/car');
      window.location.reload();
    } catch (error) {
      // console.error('Ошибка при входе в организацию:', error);
    }
  };

  const handlePersonalAccountClick = async () => {
    try {
      setSelectedAccount((prev) => 'Личный аккаунт');
      setIsOpen(false);

      localStorage.setItem('selectedAccount', 'Личный аккаунт');
      await store.logoutOrg();
      await store.getUser();
      navigate('/fleet/car');
      window.location.reload();
    } catch (error) {
      // console.error('Ошибка при выходе из личного аккаунта:', error);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const savedSelectedAccount = localStorage.getItem('selectedAccount');
    if (savedSelectedAccount && orgData?.rows.some((orgInfo) => orgInfo.name === savedSelectedAccount)) {
      setSelectedAccount(savedSelectedAccount);
    }
  }, [orgData]);

  const fetchOrgData = async () => {
    try {
      const response = await store.getOrg();
      if (response !== null && response !== undefined) {
        setOrgData(response);
      }
    } catch (error) {
      // console.error('Ошибка при получении данных:', error);
    }
  };

  const getUserInfo = async () => {
    setLoading(true);
    try {
      const response = await store.getUser();
      setUser(store.user);
      if (store.user.phone === undefined) {
        localStorage.removeItem('token');
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  function formatPhoneNumber(phoneNumber: string | undefined) {
    const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
    }
    return phoneNumber;
  }

  useEffect(() => {
    fetchOrgData();
    getUserInfo();
  }, [selectedAccount, localStorage.getItem('token')]);

  return (
    <div className={`${!isBurgerOpen ? '' : 'sideHeader-burger-modal-background'}
      sideHeader-dropdown-menu ${isOpen ? 'sideHeader-burger-modal-background' : ''}`}
    >
      <div className='sideHeader'>
        <div className="sideHeader-container">
          <div className='sideHeader-menu-container'>
            <div className='logo' onClick={() => handleNavigation('/fleet/car')}>{EvoLogoShort}</div>
            <div className='sideHeader-menuItems-container'>
              <div className={`sideHeader-autopark-container ${selectedItem === 'Автопарк' ? 'selected' : ''}`}
                onClick={() => {
                  handleNavigation('/fleet/car');
                  setSelectedMenuItem('Автопарк');
                  setSelectedItem('Автопарк');
                  // localStorage.removeItem('selectedSubItem');
                }}
                >
                <span className='sideHeader-icon icon-autopark'>{car}</span>
              </div>
              <div className={`sideHeader-map-container ${selectedItem === 'Карта' ? 'selected' : ''}`}
                onClick={() => {
                  handleNavigation('/map');
                  setSelectedMenuItem('Карта');
                  setSelectedItem('Карта');
                  // localStorage.removeItem('selectedSubItem');
                }}>
                <span className='sideHeader-icon icon-map'>{mapPin}</span>
              </div>
              <div className={`sideHeader-roaming-container ${selectedItem === 'Сеть ЭЗС' ? 'selected' : ''}`}
                onClick={() => {
                  setSelectedMenuItem('Сеть ЭЗС');
                  setSelectedItem('Сеть ЭЗС');
                  handleNavigation('/charging');
                }}
                >
                <span className='sideHeader-icon icon-roaming'>{charging}</span>
              </div>
              <div className={`sideHeader-organizations-container ${selectedItem === 'Организации' ? 'selected' : ''}`}
                onClick={() => {
                  handleNavigation('/organizations');
                  setSelectedMenuItem('Организации');
                  setSelectedItem('Организации');
                  // localStorage.removeItem('selectedSubItem');
                }}
                >
                <span className='sideHeader-icon icon-org'>{org}</span>
              </div>
              {/* <div className={`sideHeader-analytics-container ${selectedItem === 'Аналитика' ? 'selected' : ''}`}
                onClick={() => {
                  handleNavigation('/analytics');
                  setSelectedMenuItem('Аналитика');
                  setSelectedItem('Аналитика');
                }}>
                <div className='sideHeader-icon icon-analytics'>{graphicsBar}</div>
              </div> */}
            </div>
          </div>
          <div className='sideHeader-account-container'>
            <div className={`sideHeader-dropdown-menu ${isOpen ? 'open' : ''}`}>
            <div className='adaptive-sideHeader-login-icon'>
            <button className="adaptive-sideHeader-dropdown-button" onClick={toggleMenu}>
              {isOpen ?
                <span className='adaptive-sideHeader-login-icon'>{close}</span> :
                <div className='adaptive-sideHeader-login-icon'>{accountIcon}</div>
              }
            </button>
            </div>
            <button className="sideHeader-dropdown-button" onClick={toggleMenu}>
              <span className='sideHeader-login-icon'>{accountIcon}</span>
              {/* <div className='sideHeader-selected-login'>
                <span className='sideHeader-login-account'>
                {!loading
                  ? (user?.firstName || user?.lastName ? `${user?.firstName} ${user?.lastName || ''}`
                    : `+${store.user.phone}`)
                  : '...'
                }
                </span>
                <span className='sideHeader-dropdown-selected-option'>
                  {selectedAccount === 'Личный аккаунт' || localStorage.getItem('selectedAccount') === 'Личный аккаунт'
                    ? 'Личный аккаунт'
                    : selectedAccount}
                </span>
              </div> */}
            </button>
            <div className="sideHeader-dropdown-options">
              <div className='sideHeader-current-account'>
              <span className='sideHeader-account-title'>Сейчас</span>
              <div className='sideHeader-selected-account-container'>
              <div className='sideHeader-selected-account'>
                <span className='sideHeader-dropdown-selected-option-open'>
                  {selectedAccount === 'Личный аккаунт' || localStorage.getItem('selectedAccount') === 'Личный аккаунт'
                    ? 'Личный аккаунт'
                    : selectedAccount}
                </span>
                <span className='sideHeader-selected-account-name'>
                  {(user?.firstName || user?.lastName) ? `${user?.firstName} ${user?.lastName || ''}`
                    : `+${store.user.phone}`
                  }
                </span>
                <span className='sideHeader-dropdown-selected-phoneInn'>
                  {!user?.orgId && !user?.orgInn && user?.phone
                    ? formatPhoneNumber(user?.phone)
                    : (user?.orgInn !== undefined ? `ИНН: ${user?.orgInn}` : 'ИНН: -')
                  }
                </span>
              </div>
              {success}
            </div>
            {user?.orgId &&
            <span className='sideHeader-dropdown-org-navigate'>
              <LargeButtonWithIcon
                fontSize='12px'
                color={'--MyPrimary'}
                backgroundColor={'--MyWhite'}
                height='16px'
                text={'Перейти в организацию'}
                onClick={() => navigate(`/organizations/${user?.orgId}`)}
                borderRadius='10px'
              />
            </span>
            }
            {orgData?.rows &&
            orgData.rows.length > 0 ? <div className='sideHeader-dropdown-options-divider'></div> : ''}
            <div className='sideHeader-another-account-list-container'>
            {orgData?.rows &&
            orgData.rows.length > 0 ? <span className='sideHeader-account-title'>Другие аккаунты</span> : ''}
            <div className='sideHeader-another-account-list'>
              {selectedAccount !== 'Личный аккаунт' && localStorage.getItem('selectedAccount') !== 'Личный аккаунт' && (
                <li className='sideHeader-another-account' onClick={handlePersonalAccountClick}>
                  Личный аккаунт
                </li>
              )}
              {orgData?.rows
                .filter((orgRow) => orgRow.name !== 'Личный аккаунт' && orgRow.name !== selectedAccount)
                .map((orgRow) => (
                  <li
                    className='sideHeader-another-account'
                    key={orgRow._id}
                    onClick={() => handleOrgClick(orgRow._id, orgRow)}
                  >
                    {orgRow.name}
                  </li>
                ))}
              </div>
              {/* <div className='sideHeader-another-account-add-account'>
                <span>{plusAdd}</span>
                <div className='sideHeader-another-account-add'>Добавить аккаунт</div>
              </div> */}
            </div>
            <div className='sideHeader-dropdown-options-divider'></div>
            <div className='sideHeader-extra-container'>
            <span className='sideHeader-account-title'>Дополнительно</span>
              <div className='sideHeader-extra-link-list'>
                <a className='sideHeader-extra-link' href="https://www.evolute.ru/privacy" target='_blank' rel="noreferrer">
                  <span>Согласие на обработку персональных данных</span>
                  <div className='sideHeader-extra-link-svg'>{link}</div>
                </a>
                <a className='sideHeader-extra-link' href='https://yandex.ru/legal/maps_termsofuse/' target='_blank' rel="noreferrer">
                  <span>Условия использования Яндекс.Карт</span>
                  <div className='sideHeader-extra-link-svg'>{link}</div>
                </a>
                <a className='sideHeader-extra-link' href='https://yandex.ru/legal/cloud_terms_smartcaptcha/' target='_blank' rel="noreferrer">
                  <span>Условия использования Yandex SmartCaptcha</span>
                  <div className='sideHeader-extra-link-svg'>{link}</div>
                </a>
              </div>
            </div>
            <button className='adaptive-sideHeader-icons-signOut' onClick={handleLogOut}>{signOut} Выйти</button>
            </div>
             </div>
            </div>
            {/* <button className='sideHeader-icons-help'>{help}</button> */}
            <button className='sideHeader-icons-signOut' onClick={handleLogOut}>{signOut}</button>
            <button className='menu-container-burger-icon'
              onClick={handleBurgerOpen}
            >
              <span ></span>{isBurgerOpen ? close : burger}
            </button>
          </div>
        </div>
          {isBurgerOpen && <div className='burger-dropdown-options'>
            <div className='sideHeader-burger-menuItems-container'>
              <div className={`sideHeader-autopark-container ${selectedItem === 'Автопарк' ? 'selected' : ''}`}
                // onClick={() => { setSelectedMenuItem('Автопарк'); setSelectedItem('Автопарк'); }}
                onClick={() => handleNavigation('/fleet/car')}
                >
                <span className='sideHeader-icon icon-autopark'>{car}</span>
                Автопарк
              </div>
              <div className={`sideHeader-map-container ${selectedItem === 'Карта' ? 'selected' : ''}`}
                onClick={() => handleNavigation('/map')}>
                <span className='sideHeader-icon icon-map'>{mapPin}</span>
                Карта
              </div>
              <div className={`sideHeader-roaming-container ${selectedItem === 'Сеть ЭЗС' ? 'selected' : ''}`}
                // onClick={() => { setSelectedMenuItem('Сеть ЭЗС'); setSelectedItem('Сеть ЭЗС'); }}
                onClick={() => handleNavigation('/roaming/chargingStations')}
                >
                <span className='sideHeader-icon icon-roaming'>{roaming}</span>
                Сеть ЭЗС
              </div>
              <div className={`sideHeader-organizations-container ${selectedItem === 'Организации' ? 'selected' : ''}`}
                onClick={() => handleNavigation('/organizations')}
                >
                <span className='sideHeader-icon icon-org'>{org}</span>
                Организации
              </div>
              {/* <div className={`sideHeader-battery-container ${selectedItem === 'Тарифы' ? 'selected' : ''}`}
                onClick={() => handleNavigation('/rate')}>
                Тарифы
              </div> */}
            </div>
          </div>}
      </div>
    </div>
  );
};

export default observer(SideHeader);
