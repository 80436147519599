/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useContext, useEffect, useReducer, useState
} from 'react';
import './AnalyticsChargePage.css';
import { AxisOptions, Chart } from 'react-charts';
import ChargeService from '../../services/ChargeService';

const AnalyticsChargePage = () => {
  const [analyticsData, setAnalyticsData] = useState<any | null>(null);
  const [analyticsFilters, setAnalyticsFilters] = useState<any | null>(null);

  const fetchAnalyticsFilters = async () => {
    try {
      const response = await ChargeService.getAnalyticsFilters();
      setAnalyticsFilters(response.data);
      // console.log(response.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchAnalyticsData = async () => {
    try {
      const response = await ChargeService.analyticsSearch([]);
      setAnalyticsData(response.data);
      // console.log(response.data);
    } catch (error) {
      // console.log(error);
    }
  };


  const data = React.useMemo(
    () => [
      {
        label: 'CCS/SAE',
        data: [{ x: 'Мытная', y: 10 }, { x: '20', y: 10 }, { x: '30', y: 10 }, { x: '40', y: 10 }, { x: '50', y: 10 }],
      },
      {
        label: 'GB/T DC',
        data: [{ x: 'Мытная', y: 20 }, { x: '20', y: 10 }, { x: '30', y: 10 }, { x: '40', y: 10 }, { x: '50', y: 10 }],
      },
      {
        label: 'Type 2',
        data: [{ x: 'Мытная', y: 30 }, { x: '20', y: 10 }, { x: '30', y: 10 }, { x: '40', y: 10 }, { x: '50', y: 10 }],
      },
      {
        label: 'CHAdeMO',
        data: [{ x: 'Мытная', y: 30 }, { x: '20', y: 10 }, { x: '30', y: 10 }, { x: '40', y: 10 }, { x: '50', y: 10 }],
      },
      {
        label: 'IEC 62196 T1',
        data: [{ x: 'Мытная', y: 30 }, { x: '20', y: 10 }, { x: '30', y: 10 }, { x: '40', y: 10 }, { x: '50', y: 50 }],
      },
      {
        label: 'GB/T AC',
        data: [{ x: 'Мытная', y: 30 }, { x: '20', y: 10 }, { x: '30', y: 10 }, { x: '40', y: 10 }, { x: '50', y: 10 }],
      },
      {
        label: 'CHAOJI',
        data: [{ x: 'Мытная', y: 30 }, { x: '20', y: 10 }, { x: '30', y: 10 }, { x: '40', y: 10 }, { x: '50', y: 11 }],
      }
    ],
    []
  );

  const primaryAxis = React.useMemo<AxisOptions<typeof data[number]['data'][number]>>(
    () => ({
      getValue: (datum) => datum.x
    }),
    []
  );

  const secondaryAxes = React.useMemo<AxisOptions<typeof data[number]['data'][number]>[]>(
    () => [
      {
        getValue: (datum) => datum.y,
        stacked: true,
        elementType: 'bar'
      },
    ],
    []
  );

  useEffect(() => {
    fetchAnalyticsFilters();
    fetchAnalyticsData();
  }, []);

  return (
    <>
      <div className="analitycsPage-topLine">
        <div className='analitycsPage-topLine-title'>
          <h2>Аналитика</h2>
        </div>
      </div>
      <h2 style={{ display: 'flex', justifyContent: 'center' }}>Страница временно недоступна</h2>
      {/* <div style={{ width: '1000px', height: '500px' }}>
        <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes
          }}
        />
      </div> */}
    </>
  );
};

export default AnalyticsChargePage;
