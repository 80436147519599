/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import './ChargeStationInfo.css';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ChargeService from '../../services/ChargeService';
import {
  ChargePointResponse, Connector, Evse, Station
} from '../../models/response/ChargeResponse';
import {
  arrowNext,
  bucket,
  connectors28pxIcons, edit, edit28, plusAdd
} from '../../Icons/Icons';
import {
  OCPIConnectorsMap, statusColors, statusDescriptions, statusTextColors
} from '../../components/Table/MyTable';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';
import LargeButton from '../../components/buttons/LargeButton';
import { Context } from '../..';
import ResetChargeStationModal from '../../components/Modal/ResetChargeStation/ResetChargeStation';
import PrivateChargeStationModal from '../../components/Modal/PrivateChargeStation/PrivateChargeStationModal';
import AddRateToConnectorModal from '../../components/Modal/AddRatetoConnector/AddRateToConnectorModal';
import { RateElement, RateInfo } from '../../models/response/RateResponse';
import DeleteRateToConnectorModal from '../../components/Modal/DeleteRateToConnector/DeleteRateToConnectorModal';
import MediumButton from '../../components/buttons/MediumButton';
import EditStationModal from '../../components/Modal/EditStationModal/EditStationModal';
import Switch from '../../components/Switch/Switch';

const ChargeStationInfo = () => {
  const { chargePointId } = useParams<{ chargePointId: string }>();
  const navigate = useNavigate();
  const { store } = useContext(Context);

  const actualChargePointId = chargePointId || '';
  const [chargePointInfo, setChargePointInfo] = useState<Station | null>(null);
  const [selectedRateConnector, setSelectedConnector] = useState<Connector | null>(null);
  const [selectedRate, setSelected] = useState<RateInfo | null>(null);
  const [ratesEnum, setRatesEnum] = useState<RateInfo[] | []>([]);
  const [isAddRateModalOpen, setIsAddRateModalOpen] = useState(false);
  const [isDeleteRateModalOpen, setIsDeleteRateModalOpen] = useState(false);

  const [isHardResetModalOpen, setIsHardResetModalOpen] = useState(false);
  const [isSoftResetModalOpen, setIsSoftResetModalOpen] = useState(false);
  const [isPrivateModalOpen, setPrivateModalOpen] = useState(false);
  const [isEditStationModalOpen, setIsEditStationModalOpen] = useState(false);

  const fetchChargePointInfo = async () => {
    try {
      const response = await ChargeService.getChargePointV2(actualChargePointId);
      setChargePointInfo(response.data);
    } catch (error) {
      // console.error('Ошибка при получении информации о станции:', error);
    }
  };

  const fetchRates = async () => {
    try {
      const response = await ChargeService.getRates([{
        key: 'CHARGER_ID',
        values: [
          actualChargePointId
        ]
      }]);
      setRatesEnum(response.data.rows);
    } catch {
      // console.log('error');
    }
  };

  // Модалки

  // Edit Station

  const handleEditStationModalOpen = () => {
    setIsEditStationModalOpen(true);
  };

  const handleEditStationModalClose = () => {
    setIsEditStationModalOpen(false);
  };

  // Add Rate

  const handleAddRateModalOpen = (connector: Connector) => {
    setSelectedConnector(connector);
    setIsAddRateModalOpen(true);
  };

  const handleAddRateModalClose = () => {
    setSelectedConnector(null);
    setIsAddRateModalOpen(false);
  };

  // Delete Rate

  const handleDeleteRateModalOpen = (connector: Connector, rate: RateInfo) => {
    setSelected(rate);
    setSelectedConnector(connector);
    setIsDeleteRateModalOpen(true);
  };

  const handleDeleteRateModalClose = () => {
    setSelected(null);
    setSelectedConnector(null);
    setIsDeleteRateModalOpen(false);
  };

  // Hard Reset

  const handleHardResetModalOpen = () => {
    setIsHardResetModalOpen(true);
  };

  const handleHardResetModalClose = () => {
    setIsHardResetModalOpen(false);
  };

  // Soft Reset

  const handleSoftResetModalOpen = () => {
    setIsSoftResetModalOpen(true);
  };

  const handleSoftResetModalClose = () => {
    setIsSoftResetModalOpen(false);
  };

  // Приватность

  const handlePrivateModalOpen = () => {
    setPrivateModalOpen(true);
  };

  const handlePrivateModalClose = () => {
    setPrivateModalOpen(false);
  };

  useEffect(() => {
    fetchChargePointInfo();
    fetchRates();
  }, [isAddRateModalOpen, isDeleteRateModalOpen, isEditStationModalOpen]);

  // To sessions

  const handleNavSessions = (station: string | undefined) => {
    store.setStationName(station);
    // localStorage.setItem('selectedSubItem', 'Зарядные сессии');
    navigate('/chargingSessions');
  };

  // Видимость станции

  const handleVisibleStation = async () => {
    try {
      await ChargeService.hideStation(actualChargePointId);
    } catch {
      // console.log('error');
    }
  };

  return (
    <div className='chargePointInfo-container'>
      <div className='chargePointInfo-header'>
        <div className='chargePointInfo-header-title'>
          <h2 className='chargePointInfo-station-name'>Станция {chargePointInfo?.name}</h2>
          <div className='chargePointInfo-station-type'>Публичная</div>
        </div>
        <div className='chargePointInfo-header-buttons'>
        {chargePointInfo &&
          <div className='chargePointInfo-header-switch'>
            <span>Видимость станции на карте</span>
             <Switch onChange={handleVisibleStation} checked={chargePointInfo?.publish}/>
          </div>}
          {/* <LargeButtonWithIcon
            text={''}
            backgroundColor={'--MySecondaryBlue'}
            LeftIcon={edit28}
            onClick={handleEditStationModalOpen}
            color={'--MyWhite'}
            height='36px'
            width='44px'
          /> */}
          {/* <LargeButton text={'Настроить приватность'} backgroundColor={'--MySecondaryBlue'} color={'--MyPrimary'}
            onClick={handlePrivateModalOpen}
            /> */}
          <MediumButton text={'Soft reset'} backgroundColor={'--MyOrange'} color={'--MyWhite'}
            onClick={handleSoftResetModalOpen}
          />
          <MediumButton text={'Hard Reset'} backgroundColor={'--MyRed'} color={'--MyWhite'}
            onClick={handleHardResetModalOpen}
          />
          <MediumButton text={'Логи OCPP'} backgroundColor={'--MyPrimary'} color={'--MyWhite'} onClick={() => navigate(`/charging/${actualChargePointId}/logs`)}/>
        </div>
        {isPrivateModalOpen &&
          <PrivateChargeStationModal
          onClose={handlePrivateModalClose}
          isModalOpen={isPrivateModalOpen}
          title={'Настройки приватности'}
          successTransferMessage={''}
          />
        }
        {isHardResetModalOpen &&
        <ResetChargeStationModal
          onClose={handleHardResetModalClose}
          isModalOpen={isHardResetModalOpen}
          title={'Hard Reset'}
          reset={'hard reset'}
          station={chargePointInfo?.name}
          stationSerialNumber={chargePointInfo ? chargePointInfo.identifier : ''}
          accessOption={'Да'}
          successDeleteMessage={'Hard reset успешно выполнен'}
        />
        }
        {isSoftResetModalOpen &&
        <ResetChargeStationModal
          onClose={handleSoftResetModalClose}
          isModalOpen={isSoftResetModalOpen}
          title={'Soft Reset'}
          reset={'soft reset'}
          stationSerialNumber={chargePointInfo ? chargePointInfo.identifier : ''}
          station={chargePointInfo?.name}
          accessOption={'Да'}
          successDeleteMessage={'Soft reset успешно выполнен'}
        />
        }
        {(isEditStationModalOpen && chargePointInfo) &&
        <EditStationModal
          onClose={handleEditStationModalClose}
          isModalOpen={isEditStationModalOpen}
          stationInfo={chargePointInfo || null}/>
        }
      </div>
      <div className='chargePointInfo-extra-info-block'>
        <div className='chargePointInfo-extra-info'>
          <div className='chargePointInfo-extra-info-row-container'>
            <div className='chargePointInfo-extra-info-container'>
              <span className='chargePointInfo-extra-info-container-title'>ID</span>
              <span className='chargePointInfo-extra-info-container-value'>{chargePointInfo?._id}</span>
            </div>
            <div className='chargePointInfo-extra-info-container'>
              <span className='chargePointInfo-extra-info-container-title'>Модель</span>
              <span className='chargePointInfo-extra-info-container-value'>{chargePointInfo?.model ? chargePointInfo?.model : '-'}</span>
            </div>
          </div>
          <div className='chargePointInfo-extra-info-row-container'>
            <div className='chargePointInfo-extra-info-container'>
              <span className='chargePointInfo-extra-info-container-title'>Локация</span>
              <span className='chargePointInfo-extra-info-container-value'>{chargePointInfo?.address}</span>
            </div>
            <div className='chargePointInfo-extra-info-container'>
              <span className='chargePointInfo-extra-info-container-title'>Серийный номер</span>
              <span className='chargePointInfo-extra-info-container-value'>{chargePointInfo?.identifier}</span>
            </div>
          </div>
          <div className='chargePointInfo-extra-info-row-container'>
            <div className='chargePointInfo-extra-info-container'>
              <span className='chargePointInfo-extra-info-container-title'>Координаты</span>
              <span className='chargePointInfo-extra-info-container-value'>
                {chargePointInfo?.location.latitude}, {chargePointInfo?.location.longitude}
              </span>
            </div>
            <div className='chargePointInfo-extra-info-container'>
              <span className='chargePointInfo-extra-info-container-title'>Онлайн статус</span>
              <span className='chargePointInfo-extra-info-container-value'>{chargePointInfo?.status}</span>
            </div>
          </div>
          <div className='chargePointInfo-extra-info-row-container'>
            <div className='chargePointInfo-extra-info-container'>
              <span className='chargePointInfo-extra-info-container-title'>Вендор</span>
              <span className='chargePointInfo-extra-info-container-value'>{chargePointInfo?.vendor ? chargePointInfo?.vendor : '-'}</span>
            </div>
          </div>
        </div>
        <LargeButtonWithIcon
          text={'Зарядные сессии'}
          onClick={() => handleNavSessions(chargePointInfo?.name)}
          backgroundColor={'--MyWhite'}
          color={'--MyPrimary'}
          RightIcon={arrowNext}
          iconMarginLeft='8px'
        />
      </div>
      <div className='chargePointInfo-connectors-container'>
      {chargePointInfo?.evses.length !== 0 ? chargePointInfo?.evses.flatMap((evse: Evse, evseIndex) => (evse.connectors.length !== 0 ? evse.connectors.map((connector: Connector, index) => (
        <div className='chargePointInfo-connector-info' key={connector._id || index}>
          <div className='chargePointInfo-connector-container'>
            <div className='chargePointInfo-connector-header'>
              <span className='chargePointInfo-connector-icon'>
                <h3>
                  EVSE {evseIndex + 1}
                </h3>
              </span>
              <div className='chargePointInfo-connector-header-status'>
                <span className='connectors-cell-power'>{connector.power} кВт</span>
                <span className='connectors-cell-status'
                  style={{ backgroundColor: statusColors[connector.status] || '#FDEDED', color: connector.status ? statusTextColors[connector.status] : '#EA4B4B' }}>
                  {connector.status ? statusDescriptions[connector.status] || connector.status : 'Выключен'}
                </span>
              </div>
            </div>
            <div className='chargePointInfo-connector-header'>
              <div className='chargePointInfo-connector-title'>
                <span className='chargePointInfo-connector-icon'
                  dangerouslySetInnerHTML={{
                    __html: connectors28pxIcons[connector.type]
                  }}>
                </span>
                <span>{OCPIConnectorsMap[connector.type] || connector.type}</span>
              </div>
            </div>
            <div className='chargePointInfo-connector-rate-list'>
              {connector.tariffIds.length !== 0 ? (
                connector.tariffIds.map((rateId: string, rateIndex) => {
                  const rateInfo = ratesEnum.find((rateInfoData: RateInfo) => rateInfoData.id === rateId);
                  return (
                    <div key={rateIndex} className='chargePointInfo-connector-rate-container'>
                      <div className='chargePointInfo-connector-rate-title'>
                        <span className='chargePointInfo-connector-rate-title-name'>{rateInfo?.title || 'Тариф Без названия'}</span>
                        <div className='chargePointInfo-connector-rate-title-buttons'>
                          <span onClick={() => {
                            if (rateInfo) {
                              handleDeleteRateModalOpen(connector, rateInfo);
                            }
                          }}>
                            {bucket}
                          </span>
                          {isDeleteRateModalOpen && selectedRate && selectedRate.id === rateInfo?.id && selectedRateConnector && selectedRateConnector._id === connector._id &&
                            <DeleteRateToConnectorModal
                              onClose={handleDeleteRateModalClose}
                              isModalOpen={isDeleteRateModalOpen}
                              title={'Удаление тарифа'}
                              description={'Вы уверены, что хотите удалить тариф'}
                              accessOption={'Да'}
                              rateId={rateInfo.id}
                              rateTitle={rateInfo.title}
                              ratesIds={connector.tariffIds}
                              connectorId={connector._id}
                            />
                          }
                        </div>
                      </div>
                      <div className='chargePointInfo-connector-rate-separator'></div>
                      <div className='chargePointInfo-connector-rate-description'>
                        {rateInfo?.elements[0] && rateInfo.elements[0].restrictions !== undefined && rateInfo.elements[0].price_components !== undefined && rateInfo.elements.map((period: RateElement, periodIndex) => (
                          <div key={periodIndex} className='ratePage-connector-rate-title'>
                            <span className='ratePage-connector-rate-name'>Цена с {period.restrictions.start_time} до {period.restrictions.end_time}</span>
                            <span>{period.price_components[0].price} ₽/кВт•ч</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className='chargePointInfo-connector-rate-list-empty'>Нет тарифа</p>
              )}
            </div>
            <div className='chargePointInfo-connector-rate-list-button'>
              <LargeButtonWithIcon
                disabled={connector.tariffIds.length === 1}
                text={'Добавить тариф'}
                LeftIcon={plusAdd}
                width='100%'
                iconMarginRight='8px'
                backgroundColor={'--MySecondaryBlue'}
                color={'--MyPrimary'}
                fontWeight='500'
                onClick={() => { handleAddRateModalOpen(connector); }}
              />
              {connector.rate && connector.tariffIds.length === 1 &&
                <span className='chargePointInfo-connector-rate-allRates-settled'>Базовый тариф уже добавлен</span>}
            </div>
            {isAddRateModalOpen && selectedRateConnector && selectedRateConnector._id === connector._id &&
              <AddRateToConnectorModal
                chargeStationId={actualChargePointId}
                onClose={handleAddRateModalClose}
                isModalOpen={isAddRateModalOpen}
                connectorId={connector._id}
                ratesIds={connector.tariffIds}
              />
            }
          </div>
        </div>
      )) : <p key={evseIndex}>Нет коннекторов</p>
      )) : <p>Нет данных о зарядных точках</p>}
      </div>
    </div>
  );
};

export default observer(ChargeStationInfo);
